import Table from '../../components/Table';
import styled from 'styled-components';
import { withOrientationChange } from 'react-device-detect';

const ProductoContainer = styled.div`
    //display: ${ props => props.portrait ? 'block' : 'flex'};
`;
const ProdText = styled.div`
    //width: ${ props => props.portrait ? '100%' : '50%'};
    width: 100%;
`;
const ProdImage = styled(ProdText)``
const Ul = styled.ul`
    & li {
        margin-bottom: 10px;
    }
`;
const Image = styled.img`
    width: ${ props => props.portrait ? '100%' : '70%'};
`;
const Centered = styled.div`
    text-align: center;
`;
const Spacer = styled.div`
    margin: 70px 0;
`;

let Producto3 = props => {
    const { isPortrait } = props

    const Rows = [
        [ 'Cantidad de Aletas', 'Largo del Mecanismo', 'Cantidad de Comandos'],
        [ '2 Aletas', '218 mm', 'un comando'],
        [ '3 Aletas', '318 mm', 'un comando'],
        [ '4 Aletas', '418 mm', 'un comando'],
        [ '5 Aletas', '518 mm', 'un comando'],
        [ '6 Aletas', '618 mm', 'un comando'],
        [ '7 Aletas', '718 mm', 'un comando'],
        [ '8 Aletas', '818 mm', 'un comando'],
        [ '9 Aletas', '918 mm', 'un comando'],
        [ '10 Aletas', '1018 mm', 'un comando']
    ]

    return <>
        <div className="producto3">
            <ProductoContainer portrait={isPortrait}>
                <ProdText portrait={isPortrait}>
                    <h3>Esquema de mecanismo</h3>
                    <Centered>
                        <Image portrait={isPortrait} src="/img/productos/aireador-de-aluminio/esquema-de-mecanismo.png" alt="Aireador de Aluminio - Esquema de Mecanismo" loading="lazy"/>
                    </Centered>
                </ProdText>
                <ProdImage portrait={isPortrait}>
                    <Spacer/>
                    <h3>Beneficios del Mecanismo</h3>
                    <Ul>
                        <li>Movimientos de aluminio para aireadores ala alta color aluminio natural y blanco</li>
                        <li>Palanca de regulación plegable que permite apilar stock de aireadores.</li>
                        <li>Mecanismos fuertes, seguros y livianos.</li>
                        <li>Se pintan en blanco y negro</li>
                    </Ul>
                    <Spacer/>
                    <h3>Especificaciones</h3>
                    <Table rows={Rows} />
                </ProdImage>
            </ProductoContainer>
        </div>
    </>
}

Producto3 = withOrientationChange(Producto3)

export default Producto3