import Table from '../../components/Table';
import styled from 'styled-components';
import { withOrientationChange } from 'react-device-detect';

const ProductoContainer = styled.div`
    display: ${ props => props.portrait ? 'block' : 'flex'};
`;
const ProdText = styled.div`
    width: ${ props => props.portrait ? '100%' : '50%'};
`;
const ProdImage = styled(ProdText)`
    margin-left: ${ props => props.portrait ? '0' : '30px'};
`;
const Ul = styled.ul`
    & li {
        margin-bottom: 10px;
    }
`;
const Image = styled.img`
    width: ${ props => props.portrait ? '100%' : props.animada ? '75%' : '95%'};
`;
const Centered = styled.div`
    text-align: center;
`;
const Spacer = styled.div`
    margin: 50px 0;
`;

let Producto1 = props => {
    const { isPortrait } = props

    const Rows = [
        [ 'Cantidad de Aletas', 'Largo del Mecanismo', 'Cantidad de Comandos'],
        [ '10 Aletas', '620 mm', 'un comando'],
        [ '11 Aletas', '680 mm', 'un comando'],
        [ '12 Aletas', '740 mm', 'un comando'],
        [ '13 Aletas', '800 mm', 'un comando'],
        [ '14 Aletas', '860 mm', 'un comando'],
        [ '15 Aletas', '920 mm', 'un comando'],
        [ '16 Aletas', '980 mm', 'un comando'],
        [ '17 Aletas', '1040 mm', 'doble comando'],
        [ '18 Aletas', '1100 mm', 'doble comando'],
        [ '19 Aletas', '1160 mm', 'doble comando'],
        [ '20 Aletas', '1220 mm', 'doble comando'],
        [ '21 Aletas', '1280 mm', 'doble comando'],
        [ '22 Aletas', '1340 mm', 'doble comando'],
        [ '23 Aletas', '1400 mm', 'doble comando'],
        [ 'Perfiles: Tablilla postigón, unión, dintel umbral e inferior puerta balcón.']
    ]

    return <>
        <div className="producto1">
            <ProductoContainer portrait={isPortrait}>
                <ProdText portrait={isPortrait}>
                    <h3>Corte de ranura en el mecanismo para la colocación del perfil</h3>
                    <Image portrait={isPortrait} src="/img/productos/postigon-de-aluminio/corte_ranura.png" alt="Postigon de Aluminio - corte ranura" loading="lazy"/>
                    <Spacer/>
                    <h3>Dimensiones</h3>
                    <Image portrait={isPortrait} src="/img/productos/postigon-de-aluminio/dimensiones1.png" alt="Postigon de Aluminio - Dimensiones y Pesos" loading="lazy"/>
                    <Image portrait={isPortrait} src="/img/productos/postigon-de-aluminio/dimensiones2.png" alt="Postigon de Aluminio - Dimensiones y Pesos" loading="lazy"/>
                </ProdText>
                <ProdImage portrait={isPortrait}>
                    <h3>Diagrama de Ensamble</h3>
                    <Centered>
                        <Image portrait={isPortrait} animada={true} src="/img/productos/postigon-de-aluminio/animacion-diagrama-ensamble-tornillo.gif" alt="Postigon de Aluminio - Diagrama de Ensamble" loading="lazy"/>
                    </Centered>
                    <Spacer/>
                    <h3>Beneficios del Mecanismo</h3>
                    <Ul>
                        <li>Material resistente al paso del tiempo y factores climáticos, especial para viviendas ubicadas en la costa.</li>
                        <li>Reemplaza a las cortinas ya que posee mecanismos que regulan la apertura de sus aletas, permitiendo una mayor y mejor ventilación e iluminación.</li>
                        <li>Puede ser utilizado para postigones regulables de abrir, fijos y corredizos con tablillas de cierre que no permiten la entrada de agua y luz.</li>
                        <li>También trabajada con tablilla vertical se pueden aplicar a garages.</li>
                        <li>Mecanismo de regulación fuerte y liviano con doble varilla en su interior pintada con palanca de regulación plegada al máximo.</li>
                        <li>Perfiles y mecanismos de aluminio especiales, livianos y económicos.</li>
                        <li>Pintados al horno con pintura de alta resistencia en diferentes colores.</li>
                    </Ul>
                    <Spacer/>
                    <h3>Ajuste del Mecanismo</h3>
                    <Centered>
                        <Image portrait={isPortrait} animada={false} src="/img/productos/postigon-de-aluminio/animacion-ajuste-postigon-768-blanco.gif" alt="Postigon de Aluminio - Ajuste" loading="lazy"/>
                    </Centered>
                    <Spacer/>
                    <h3>Especificaciones</h3>
                    <Table rows={Rows} />
                </ProdImage>
            </ProductoContainer>
        </div>
    </>
}

Producto1 = withOrientationChange(Producto1)

export default Producto1