import { withOrientationChange } from 'react-device-detect';
import { Link } from 'wouter';
import Card from '../../components/Card';
import ProductList from '../Productos/ProductList';
import styled from 'styled-components';

const BigImage = styled.div`
    width: 100%;
    height: 500px;
    position: relative;
    background-image: ${ props => `url('${process.env.PUBLIC_URL}/img/Header-Home.jpg')` };
    background-position: ${ props => props.portrait ? 'left' : '' };
    background-size: ${ props => props.portrait ? 'cover' : '100% 100%' };
`;
const BigInfoContainer = styled.div`
    color: #fff;
    max-width: 500px;
    position: absolute;
    left: ${ props => props.portrait ? '4%' : '30%'};
    right: ${ props => props.portrait ? '4%' : '0'};
    bottom: 170px;
`;
const BigText = styled.div`
    font-size: 2.5em;
    margin-bottom: 30px;
    letter-spacing: 3px;
    text-shadow: 4px 1px 11px black;
`;
const BigButton = styled.div`
    background-color: #903;
    text-align: center;
    border-radius: 100px;
    width: ${ props => props.portrait ? 'auto': '50%'};
    font-weight: bold;
    border: solid 2px #903;
    transition: background-color .5s;
    & a {
        color: #fff;
        text-decoration: none;
        padding: 15px 10px;
        display: block;
    }
    &:hover {
        background-color: transparent;
    }
`;
const BigContactContainer = styled.div`
    width: 75%;
    height: 150px;
    background-color: #903;
    position: absolute;
    bottom: ${ props => props.portrait ? '-100px' : '-75px'};
    left: 50%;
    transform: translateX(-50%);
    display: ${ props => props.portrait ? 'block' : 'flex'};
    align-items: center;
    padding: ${ props => props.portrait ? '30px 30px' : '0 30px 0 30px'};
    z-index: ${ props => props.portrait ? '1' : '0'};
`;
const BigContactText = styled.div`
    color:#fff;
    font-size: 2em;
    width: ${ props => props.portrait ? '100%' : '80%'};
    padding: ${ props => props.portrait ? '0' : '0 30px 0 0'};
    font-style: oblique;
`;
const BigButtonContact = styled(BigButton)`
    background-color: #fff;
    border-color: #fff;
    width: ${ props => props.portrait ? '50%' : '20%'};
    margin-top: ${ props => props.portrait ? '15px' : '0'};
    margin-left: ${ props => props.portrait ? '50%' : '0'};
    transform: ${ props => props.portrait ? 'translateX(-50%)' : 'none'};

    & a {
        color: #000;
    }
    &:hover {
        background-color: transparent;
        color: #fff;
        & a {
            color: #fff;
        }
    }
`;
const CardsContainer = styled.div`
    display: ${ props => props.portrait ? 'block' : 'flex'};
    justify-content: center;
`;
const Section = styled.section`
    margin: ${ props => props.portrait ? '75px 5%' : '75px 10%'};
    padding: 40px 0;
`;
const Spacer = styled.div`
    height: 50px;
    position: relative;
    z-index: -10;
`;

let Home = props => {
    const { isPortrait } = props

    const Cards = () => {
        return (
            ProductList.map( product => {
                return <Card props={product} key={product.to}/>
            })
        )
    }
    
    return <>
        <BigImage portrait={isPortrait}>
            <BigInfoContainer portrait={isPortrait}>
                <BigText>
                    Mecanismos regulables y perfiles
                </BigText>
                <BigButton portrait={isPortrait}>
                    <Link to="/productos">Conozca nuestros productos</Link>
                </BigButton>
            </BigInfoContainer>
            <BigContactContainer portrait={isPortrait}>
                <BigContactText portrait={isPortrait}>Somos su mejor opci&oacute;n en la industria</BigContactText>
                <BigButtonContact portrait={isPortrait}>
                    <Link to="/contacto">Cont&aacute;ctenos</Link>
                </BigButtonContact>
            </BigContactContainer>
        </BigImage>
        <Spacer/>
        <Section portrait={isPortrait}>
            <CardsContainer portrait={isPortrait}>
                <Cards />
            </CardsContainer>
        </Section>
    </>
}

Home = withOrientationChange(Home)

export default Home