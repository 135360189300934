import { useState } from 'react';
import MenuList from './MenuList';
import styled from 'styled-components';

const Bars = styled.div`
    font-size: 2em;
    padding: 0px 4px;
`;

export default function Mobile ( { items } ) {
    const [ Show, setShow ] = useState('hide');

    const handelOpen = () => {
        setShow('')
    }
    
    return <>
        <Bars onClick={handelOpen}>
            <i className="fas fa-bars"></i>
        </Bars>
        <MenuList items={items} show={Show} stateChanger={setShow}/>
    </>
}