import { isBrowser } from 'react-device-detect';
import { useContext } from "react";
import businessInfo from '../../context/businessInfo'
import Menu from '../Menu';
import styled from 'styled-components';

const HeaderContainer = styled.div`
    margin: 0 auto;
    display: flex;
    align-items: center;
    color: #fff;
    max-width: 1140px;
`;
const SiteBranding = styled.div`
    margin: 0.875em auto 0.875em 0;
    max-width: 300px;
    min-width: 0;
    overflow: hidden;
`;
const Image = styled.img`
    width: 100%;
    filter: invert() brightness(10);
    &.isMobile {
        width: 95%;
        margin-left: 10px;
    }
`;

export default function Header () {
    const Context = useContext(businessInfo)
    const Device = isBrowser ? '' : 'isMobile';
    const BgMobile = isBrowser ? '' : 'bgMobile';

    return <>
        <HeaderContainer className={BgMobile}>
            <SiteBranding>
                <a href="/">
                    <Image className={Device} src="/img/isoCompleteTransparent2.png" alt={Context.name}/>
                </a>
            </SiteBranding>
            <Menu/>
        </HeaderContainer>
    </>
}