import { withOrientationChange } from 'react-device-detect';
import styled from 'styled-components';

const NosotrosStyled = styled.div`
    background-color: #fff;
    & p {
        color: #797979;
    }
`;
const Section = styled.section`
    margin: ${ props => props.portrait ? '0 10%' : '75px 10%'};
    padding: 40px 0;
`;
const Empresa = styled.div`
    display: ${ props => props.portrait ? 'block' : 'flex'};
    align-items: center;
`;
const EmpresaTexto = styled.div`
    width: ${ props => props.portrait ? '100%' : '50%'};
    & h2 {
        font-weight: 500;
    }
`;
const Ul = styled.ul`
    list-style-type: none;
    margin: ${ props => props.portrait ? '30px 0' : 0};
    padding: 0;
    font-size: 1.2em;
    & li {
        display: flex;
        & .fa-check {
            color: #903;
        }
    }
`;
const SpanText = styled.span`
    margin: 0 0 10px 10px
`;
const Image = styled.div`
    display: flex;
    width: ${ props => props.portrait ? '100%' : '50%'};
    justify-content: center;
    align-items: center;
    & div {
        background-color: #ccc;
        width: 90%;
        height: 300px;
        background-size: cover;
    }
`;

let Nosotros = props => {
    const { isPortrait } = props

    return <>
        <NosotrosStyled portrait={isPortrait}>
            <Section portrait={isPortrait}>
                <Empresa portrait={isPortrait}>
                    <EmpresaTexto portrait={isPortrait}>
                        <h2>Sobre nosotros</h2>
                        <p>Somos una empresa con mas de 30 años de experiencia en la fabricación de aireadores y postigones de aluminio en la República Argentina.</p>
                        <Ul portrait={isPortrait}>
                            <li>
                                <span><i className="fas fa-check"></i></span>
                                <SpanText>
                                    Proveemos nuestros productos a carpinterías de aluminio y madera.
                                </SpanText>
                            </li>
                            <li>
                                <span><i className="fas fa-check"></i></span>
                                <SpanText>
                                    Nuestra fábrica cuenta con más de 1200m2
                                </SpanText>
                            </li>
                            <li>
                                <span><i className="fas fa-check"></i></span>
                                <SpanText>
                                    Estamos situados estrategicamente en la localidad de San Justo, Provincia de Buenos Aires.
                                </SpanText>
                            </li>
                        </Ul>
                    </EmpresaTexto>
                    <Image portrait={isPortrait}>
                        <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/aluminio.jpeg)`}}></div>
                    </Image>
                </Empresa>
            </Section>
            <Section portrait={isPortrait}>
                <p>
                Nuestro progreso en los últimos años coincide con el crecimiento del aluminio en la construcción en Argentina, que sumado a la experiencia y tecnología adquirida, nos permite interpretar las necesidades de nuestros clientes, ofreciéndoles soluciones integrales para la carpintería de aluminio y madera.
                </p>
                <p>
                Participamos en las exposiciones de la construcción que se realizan en el país. De esta forma podemos llegar a mostrar nuestros productos a una gran cantidad de público y profesionales de la construcción.
                </p>
                <p>
                En un comienzo se realizaban aireadores, mecanismos y perfiles que se fueron modificando y evolucionando para ofrecer hoy una línea de productos sumamente innovadora.
                </p>
                <p>
                El sistema de regulación para los aireadores y postigones de aluminio y madera, permiten mediante su accionamiento una abertura práctica, eficáz y óptima.
                </p>
                <p>
                Así seguiremos avanzando y ofreciendo los productos que el público solicita siempre dentro de una marca de calidad, seriedad y excelencia.
                </p>
            </Section>
        </NosotrosStyled>
    </>
}

Nosotros = withOrientationChange(Nosotros)

export default Nosotros