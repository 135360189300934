import Producto1 from './Producto1';
import Producto2 from './Producto2';
import Producto3 from './Producto3';

const ProductList = [
    { 
        name:'Aireador de Aluminio', 
        id:1, 
        to: 'aireador-de-aluminio', 
        path: '/productos/aireador-de-aluminio',
        component: <Producto3/>, 
        description: 'Movimientos de aluminio para aireadores', 
        img: `url(${process.env.PUBLIC_URL}/img/productos/aireador-de-aluminio/foto_home_aireador.png)` },
    { 
        name:'Postigon de Aluminio', 
        id:2, 
        to: 'postigon-de-aluminio',
        path: '/productos/postigon-de-aluminio',
        component: <Producto1/>, 
        description: 'Permite una mayor y mejor ventilacion e iluminacion', 
        img: `url(${process.env.PUBLIC_URL}/img/productos/postigon-de-aluminio/foto_home_postigon.png)` },
    { 
        name:'Postigon de Madera', 
        id:3, 
        to: 'postigon-de-madera', 
        path: '/productos/postigon-de-madera',
        component: <Producto2/>,  
        description: 'Mecanismos livianos, inalterables, economicos y fuertes', 
        img: `url(${process.env.PUBLIC_URL}/img/productos/postigon-de-madera/foto_home_postigon.png)` },
]

export default ProductList
