import styled from 'styled-components';

const TableContainer = styled.div`
    background-color: #F1F1F1;
    padding: 2px
`
const Row = styled.div`
    display: flex;
    font-weight: 500;
    text-align: center;
    
    &:first-of-type {
        font-weight: bold;
        & div {
            background-color: #f7bd00;
        }
    }
    
`
const Col = styled.div`
    flex: 1;
    justify-content: center;
    margin: 2px;
    background-color: #F2DFB6;
`

export default function Table (params) {
    const { rows } = params
    
    const generateKey = (pre) => {
        return `${ pre }_${ Math.random() }`;
    }

    const TableContent = ({rows}) => {
        return rows.map( (row, i) => {
            return <Row key={generateKey('row')}>
                {
                    row.map( (col, x) => {
                        return <Col key={generateKey('col')}>
                                    {col}
                                </Col>
                    })
                }
            </Row>
        })
    }
    return <>
        <TableContainer>
            <TableContent rows={rows} />
        </TableContainer>
    </>
}