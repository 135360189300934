import { Link, useLocation } from 'wouter';
import styled from 'styled-components';

const Ul = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;
const Li = styled.li`
    display: inline-block;
    list-style-type: none;
    & a {
        margin: 5px 0 5px 20px;
        font-weight: 700;
        font-size: 16px;
        text-decoration: none;
        color: #fff;
        &:hover, &.isActive {
            color: #f7bd00;
        }
    }
`;

export default function Browser ( { items } ) {
    const [location] = useLocation()
    
    let active = ''

    const MenuList = items.map( item => {
        active = (`/${location.split('/')[1]}` === item.path) ? 'isActive' : ''
        return <Li key={item.itemName}><Link to={item.path} className={active}>{ item.itemName.toUpperCase() }</Link></Li>
    })

    return <>
        <div>
            <Ul>
                { MenuList }
            </Ul>
        </div>
    </>
}