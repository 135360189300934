import { BrowserView, MobileView, isBrowser } from 'react-device-detect';
import TopSocialBar from './components/TopSocialBar';
import Header from './components/Header';
import { Route } from 'wouter';
import Home from './pages/Home';
import Contacto from './pages/Contacto';
import Ferias from './pages/Ferias';
import Nosotros from './pages/Nosotros';
import Productos from './pages/Productos';
import { useState } from 'react';
import WhatsApp from './components/WhatsApp';
import Footer from './components/Footer';
import styled from 'styled-components';

const AppStyled = styled.div`
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 500;
`;
const HeaderStyled = styled.header`
  position: fixed;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 0;
  border-top: 1px solid rgba(255,255,255,0.1);
  border-bottom: 1px solid rgba(255,255,255,0.1);
  background-color: #903;
  transition: top .5s;
  &.isMobile {
    position: relative;
  }
  &.isFixedToTop {
    position: fixed;
    top: 0px;
  }
`;

function App() {
  const [ fixed, setFixed] = useState('')

  const HeaderBlock = () => {

    window.onscroll = scrl => {
        let pos = document.documentElement.scrollTop
        if (pos > 0) {
          setFixed('isFixedToTop')
        } else {
          setFixed('')
        }
    }

    const deviceClass = isBrowser ? `` : `isMobile`;
    

    return (
      <HeaderStyled className={`${deviceClass} ${fixed}`}>
        <Header/>
      </HeaderStyled>
    )
  };

  return (
      <AppStyled>
        <BrowserView renderWithFragment={true}>
          <TopSocialBar/>
          <HeaderBlock/>
        </BrowserView>
        <MobileView renderWithFragment={true}>
          <HeaderBlock/>
          <TopSocialBar/>
        </MobileView>

        <WhatsApp/>
        
        <Route path="/" component={Home} />
        <Route path="/contacto" component={Contacto} />
        <Route path="/ferias-y-exposiciones" component={Ferias} />
        <Route path="/nosotros" component={Nosotros} />
        <Route path="/productos" component={Productos} />
        <Route path="/productos/:to" component={Productos} />

        <Footer/>
      </AppStyled>
  );
}

export default App;
