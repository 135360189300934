import { useEffect, useState } from 'react';
import { Link, useLocation } from 'wouter';
import styled from 'styled-components';

const MenuListContainer = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #000;
    color: #fff;
    text-align: center;
    padding-top: 50%;
    padding-bottom: 50%;
    & ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    &.hide {
        display: none;
    }
`;
const Li = styled.li`
    padding: 10px;
    border-bottom: solid 1px #999;
    &.isActive {
        background-color: #fff;
    }
    &:last-child {
        border-bottom: none;
    }
    & a {
        color: #fff;
        text-decoration: none;
        &.isActive {
            color: #903;
            font-weight: bold;
        }
    }
`;
const CloseMenu = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2em;
`;

export default function MenuList ( { items, show, stateChanger } ) {
    const [ Show, setShow ] = useState(show)

    const [location] = useLocation()
    let active = ''
    
    useEffect(function () {
        setShow(show)
    }, [show])

    const handleClose = () => {
        setShow('hide')
        stateChanger('hide')
    }

    let Items = items.map( item => {
        active = (`/${location.split('/')[1]}` === item.path) ? 'isActive' : ''
        return <Li key={item.itemName} className={active}>
            <Link to={item.path} onClick={handleClose} className={active}>{ item.itemName.toUpperCase() }</Link>
        </Li>
    })

    const height = () => {
        return { height:  window.outerHeight + 'px' };
    }

    return <>
        <MenuListContainer className={Show} style={height()}>
            <CloseMenu onClick={handleClose}>
                <i className="far fa-window-close"></i>
            </CloseMenu>
            <ul>{Items}</ul>
        </MenuListContainer>
    </>
}