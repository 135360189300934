import { useContext } from "react";
import businessInfo from '../../context/businessInfo'
import styled from 'styled-components';

const Ul = styled.ul`
    display: none;
`;
const Li = styled.li`
    margin: 0 7px;
    font-size: 14px;
    display: inline-block;
    list-style-type: none;
`;
const A = styled.a`
    color: #000;
`;

export default function SocialWidget () {
    const Context = useContext(businessInfo)

    return <>
        <Ul className="socialWidget">
            <Li>
                <A href={Context.facebook} target="_blank" rel="noreferrer">
                    <i className="fab fa-facebook-f"></i>
                </A>
            </Li>
            <Li>
                <A href={Context.instagram} target="_black" rel="noreferrer">
                    <i className="fab fa-instagram"></i>
                </A>
            </Li>
            <Li>
                <A href={Context.whatsapp} target="_blank" rel="noreferrer">
                    <i className="fab fa-whatsapp"></i>
                </A>
            </Li>
        </Ul>
    </>;
}