import { withOrientationChange } from 'react-device-detect';
import { useContext } from "react";
import businessInfo from '../../context/businessInfo';
import styled from 'styled-components';

const FooterContainer = styled.div`
    background-color: #1a1a1a;
`;
const Section = styled.section`
    margin: ${ props => props.portrait ? '0 10%' : '75px 10% 0'};
    padding: 40px 0;
`;
const BusinessInfoContainer = styled.div`
    background-color: rgba(0,0,0,0.3);
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: rgba(255,255,255,0.1);
    padding: 20px;
`;
const BusinessInfoContent = styled.div`
    display: ${ props => props.portrait ? 'block' : 'flex'};
    justify-content: center;
    align-items: center;
`;
const InfoBoxStyled = styled.div`
    color: #ccc;
    width: ${ props => props.portrait ? '100%' : '33.3%'};
    display: flex;
`;
const InfoIcon = styled.div`
    font-size: ${ props => props.portrait ? '1.8em' : '2.5em'};
    display: flex;
    align-items: center;
    width: ${ props => props.portrait ? '10%' : ''};
`;
const InfoText = styled.div`
    padding: ${ props => props.portrait ? '0 20px 0 20px' : '0 30px 0 20px'};
    & h3 {
        font-weight: 500;
    }
    & p {
        margin-top: -10px;
    }
`;
const Spacer = styled.div`
    height: 50px;
    position: relative;
    z-index: -10;
`;

let Footer = props => {
    const { isPortrait } = props
    const Context = useContext(businessInfo)
    const { addressInfo, email, phone } = Context;
    const infoList = [addressInfo, email, phone];
    const icons = ['fa-map-marker-alt','fa-envelope','fa-phone'];
    const titles = ['', 'Email :', 'Contactenos :'];
    
    const InfoBox = () => {
        return infoList.map( (info, i) => {
            return (
                <InfoBoxStyled portrait={isPortrait} key={500+i}>
                    <InfoIcon portrait={isPortrait}>
                        <i className={`fas ${icons[i]}`}></i>
                    </InfoIcon>
                    <InfoText portrait={isPortrait}>
                        <h3>{ info?.door || titles[i] }</h3>
                        {
                            i > 0
                                ? <p>{ info }</p>
                                : <p>{`${info.city}, ${info.cp} - ${info.country}`}</p>
                        }
                    </InfoText>
                </InfoBoxStyled>
            )
        })
    }

    return <>
        <FooterContainer portrait={isPortrait}>
            <Section>
                <BusinessInfoContainer>
                    <BusinessInfoContent portrait={isPortrait}>
                        <InfoBox/>
                    </BusinessInfoContent>
                </BusinessInfoContainer>
            </Section>
            {
                isPortrait && <Spacer/>
            }
        </FooterContainer>
    </>
}

Footer = withOrientationChange(Footer)

export default Footer