import styled from "styled-components";
import { Link } from 'wouter';
import { withOrientationChange } from 'react-device-detect';

const CardStyled = styled.div`
    width: ${ props => props.portrait ? `auto` : `25%`};
    background-color: #fff;
    min-height: 300px;
    margin: ${ props => props.portrait ? `0 0 30px` : `0 10px`};
    transition: background-color .5s, color .5s;
    cursor: pointer;
    &:first-child, &:last-child {
        width: ${ props => props.portrait ? `auto` : `25%`};
        margin: ${ props => props.portrait ? `0 0 30px` : `0 0 0 5px`};
    }
    & h3 {
        font-size: 18px;
        font-weight: 500;
        color: #000;
    }
    & p {
        color: #797979;
    }
    & a {
        text-decoration: none;
    }
    &:hover {
        background-color: #1a1a1a;
        color: #fff;
        & h3 {
            color: #fff;
        }
    }
`;
const CardImage = styled.div`
    height: 250px;
    background-color: #797979;
    // background-size: 140%;
    background-size: 100% 100%;
    // background-position: center 95%;
    background-image: ${props => props.bgimage}
`;
const CardContent = styled.div`
    margin: 0 15px 30px;
    text-align: center;
`;

let Card = ({isPortrait, props}) => {
    const { path, img, name, description } = props
    
    return <>
        <CardStyled portrait={isPortrait}>
            <Link to={path}>
                <CardImage bgimage={img} />
                <CardContent>
                    <h3>{name}</h3>
                    <p>{description}</p>
                </CardContent>
            </Link>
        </CardStyled>
    </>
}

Card = withOrientationChange(Card)

export default Card