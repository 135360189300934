import { BrowserView, withOrientationChange } from 'react-device-detect';
import { useContext } from "react";
import businessInfo from '../../context/businessInfo';
import styled from 'styled-components';

const ContactWidgetStyled = styled.ul`
    text-align: ${ props => props.portrait ? 'center' : 'right'};
    padding: ${ props => props.portrait ? '0px' : 'inherit'};
`;
const Li = styled.li`
    margin: 0 7px;
    font-size: ${ props => props.portrait ? '1.18em' : '14px'};
    display: inline-block;
    list-style-type: none;
`;


let ContactWidget = props => {
    const { isPortrait } = props

    const Context = useContext(businessInfo)

    const Email = () => {
        return (
            <Li portrait={isPortrait}>
                <span className="fa fa-envelope"></span>
                <span>&nbsp; {Context.email}</span>
            </Li>
        )
    }
    
    return <>
        <ContactWidgetStyled portrait={isPortrait}>
            <Li portrait={isPortrait}>
                <span className="fa fa-phone"> </span>
                <span>&nbsp; {Context.phone}</span>
            </Li>
            <BrowserView renderWithFragment={true}>
                <Email/>
            </BrowserView>
        </ContactWidgetStyled>
    </>;
}

ContactWidget = withOrientationChange(ContactWidget)

export default ContactWidget