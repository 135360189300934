import ProductsAll from './ProductsAll';
import ProductsMenu from './ProductsMenu';
import { withOrientationChange } from 'react-device-detect';
import styled from 'styled-components';

const ProductosStyled = styled.div`
    background-color: #fff;
    & h1 {
        text-align: center;
    }
`;
const Section = styled.section`
    margin: ${ props => props.portrait ? '0 10% 75px' : '75px 10%'};
    padding: 40px 0;
`;
const YellowLine = styled.div`
    border: solid 1px #f7bd00;
`;
const Spacer = styled.div`
    height: 50px;
    position: relative;
    z-index: -10;
`;

let Productos = props => {
    const { params , isPortrait } = props;
    const { to } = params;

    return <>
        <ProductosStyled>
            <Section portrait={isPortrait}>
                <h1>Nuestros Productos</h1>
                <YellowLine/>
                <ProductsMenu />
                <YellowLine/>
                <Spacer/>
                <ProductsAll product={to} />
            </Section>
        </ProductosStyled>
    </>
}

Productos = withOrientationChange(Productos)

export default Productos