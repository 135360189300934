import ProductList from './ProductList';
import { Link, useLocation } from 'wouter';
import styled from 'styled-components';
import { withOrientationChange } from 'react-device-detect';

const Ul = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: ${ props => props.portrait ? 'block' : 'flex'};
    justify-content: center;
`;
const LiStyled = styled.li`
    display: inline-block;
    list-style-type: none;
    margin: 0 15px;
`;
const H3 = styled.h3`
    color: #903;
    text-decoration: underline;
    cursor: pointer;
    &:hover, & ${props => !props.isactive} {
        color: #f7bd00;
    }
`;

let ProductsMenu = props => {
    const [location] = useLocation()

    const { isPortrait } = props
    
    let isActive = false
    
    const Li = ProductList.map( item => {
        isActive = (location === `/productos/${item.to}` || (location === `/productos` && item.id === 1) )
                    ? true
                    : false
        return (
            <LiStyled key={item.name}>
                <Link to={`/productos/${item.to}`}>
                    <H3 isactive={isActive}>{item.name}</H3>
                </Link>
            </LiStyled>
        )
    })
    return <>
        <Ul portrait={isPortrait}>
        {Li}
        </Ul>
    </>
}

ProductsMenu = withOrientationChange(ProductsMenu)

export default ProductsMenu