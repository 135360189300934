import { withOrientationChange } from 'react-device-detect';
import styled from 'styled-components';

const WhatsappButton = styled.div`
    position:fixed;
    right: ${ props => props.portrait ? 'auto' : '20px'};
    bottom: ${ props => props.portrait ? '0px' : '20px'};
    width: ${ props => props.portrait ? '100%' : '70px'};
    height: ${ props => props.portrait ? '50px' : '70px'};
    background-color: #25D366;
    z-index: 5;
    border: ${ props => props.portrait ? 'none' : 'solid'};
    border-radius: ${ props => props.portrait ? 'inherit' : '100%'};
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 2.5em;
    box-shadow: ${ props => props.portrait ? '0px -3px 5px 0px rgba(0,0,0,0.45)' : '3px 3px 5px 0px rgba(0,0,0,0.75)'};
    -webkit-box-shadow: ${ props => props.portrait ? '0px -3px 5px 0px rgba(0,0,0,0.45)' : '3px 3px 5px 0px rgba(0,0,0,0.75)'};
    -moz-box-shadow: ${ props => props.portrait ? '0px -3px 5px 0px rgba(0,0,0,0.45)' : '3px 3px 5px 0px rgba(0,0,0,0.75)'};
    transition: filter .5s, transform .5s;
    & a {
        color: #fff;
        padding: 15px 20px;
        display: block;
    }
    &:hover {
        filter: brightness(1.2);
        transform: scale(1.1);
    }
`;

let WhatsApp = props => {
    const { isPortrait } = props
    const WhatsAppNumber = '5491155778861';
    const WhatsAppLink = `https://api.whatsapp.com/send?phone=${WhatsAppNumber}`;

    return <>
        <WhatsappButton portrait={isPortrait}>
            <a href={WhatsAppLink} target="_blank" rel="noreferrer">
                <i className="fab fa-whatsapp"></i>
            </a>
        </WhatsappButton>
    </>
}

WhatsApp = withOrientationChange(WhatsApp)

export default WhatsApp