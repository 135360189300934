import React from "react";

const Context = React.createContext({
    name: 'Eusebio Martinez',
    email: 'info@eusebiomartinezsrl.com',
    phone: '(011)-4651-8418 / 4484-2463',
    addressInfo: {
        address: 'Sarratea 2168 - Buenos Aires - CP: 1754',
        door: 'Sarratea 2168',
        country: 'Argentina',
        city: 'Buenos Aires',
        cp: 'CP: 1754',
        googleapikey: 'AIzaSyC4eM2vDhCOEFftgbsZ5-lO248KsR79jms',
        coords: { lat: -34.675633122827655, lng: -58.577892644712556 },
    },
    facebook: '#',
    instagram: '#',
    whatsapp: '#',
    pages: [
        { itemName: 'Home', component: 'Home' , path: '/'},
        { itemName: 'Productos', component: 'Productos', path: '/productos' },
        { itemName: 'Ferias y Exposiciones', component: 'Ferias', path: '/ferias-y-exposiciones' },
        { itemName: 'Nosotros', component: 'Nosotros', path: '/nosotros' },
        { itemName: 'Contacto', component: 'Contacto', path: '/contacto' },
    ]
});

export default Context