import Table from '../../components/Table';
import styled from 'styled-components';
import { withOrientationChange } from 'react-device-detect';

const ProductoContainer = styled.div`
    // display: ${ props => props.portrait ? 'block' : 'flex'};
`;
const ProdText = styled.div`
    // width: ${ props => props.portrait ? '100%' : '50%'};
    width: 100%;
`;
const ProdImage = styled(ProdText)``
const Ul = styled.ul`
    & li {
        margin-bottom: 10px;
    }
`;
const Image = styled.img`
    width: ${ props => props.portrait ? '100%' : '70%'};
`;
const Centered = styled.div`
    text-align: center;
`;
const Spacer = styled.div`
    margin: 70px 0;
`;

let Producto2 = props => {
    const { isPortrait } = props

    const Rows = [
        [ 'Cantidad de Aletas', 'Largo del Mecanismo', 'Cantidad de Comandos'],
        [ '10 Aletas', '620 mm', 'un comando'],
        [ '11 Aletas', '680 mm', 'un comando'],
        [ '12 Aletas', '740 mm', 'un comando'],
        [ '13 Aletas', '800 mm', 'un comando'],
        [ '14 Aletas', '860 mm', 'un comando'],
        [ '15 Aletas', '920 mm', 'un comando'],
        [ '16 Aletas', '980 mm', 'un comando'],
        [ '17 Aletas', '1040 mm', 'doble comando'],
        [ '18 Aletas', '1100 mm', 'doble comando'],
        [ '19 Aletas', '1160 mm', 'doble comando'],
        [ '20 Aletas', '1220 mm', 'doble comando'],
        [ '21 Aletas', '1280 mm', 'doble comando'],
        [ '22 Aletas', '1340 mm', 'doble comando'],
        [ '23 Aletas', '1400 mm', 'doble comando']
    ]

    return <>
        <div className="producto2">
            <ProductoContainer portrait={isPortrait}>
                <ProdText portrait={isPortrait}>
                    <h3>Diferentes tallados para el mecanismo</h3>
                    <Centered>
                    <Image portrait={isPortrait} src="/img/productos/postigon-de-madera/diferentes_tallados.png" alt="Postigon de Madera - Diferentes Tallados" loading="lazy"/>
                    </Centered>
                </ProdText>
                <ProdImage portrait={isPortrait}>
                    <Spacer/>
                    <h3>Beneficios del Mecanismo</h3>
                    <Ul>
                        <li>Mecanismos livianos, inalterables, económicos y fuertes, con doble varilla pintada en su interior.</li>
                        <li>Regulación total de sus aletas para una mejor y mayor ventilación e iluminación, con palanca plegable.</li>
                        <li>Aluminio pintado al horno con pintura de alta resistencia.</li>
                    </Ul>
                    <Spacer/>
                    <h3>Especificaciones</h3>
                    <Table rows={Rows} />
                </ProdImage>
            </ProductoContainer>
        </div>
    </>
}

Producto2 = withOrientationChange(Producto2)

export default Producto2