import { BrowserView, MobileView } from 'react-device-detect';
import Mobile from './Mobile';
import Browser from './Browser';
import { useContext } from 'react';
import businessinfo from '../../context/businessInfo';
import styled from 'styled-components';

const MenuContainer = styled.div`
    margin-right: 10px;
`;

export default function Menu () {
    const {pages} = useContext(businessinfo);

    return <>
        <MenuContainer>
            <MobileView>
                <Mobile items={pages}/>
            </MobileView>
            <BrowserView>
                <Browser items={pages}/>
            </BrowserView>
        </MenuContainer>
    </>
};