import React, { useState } from 'react';
import { send } from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';

const Form = styled.form`
    width: ${ props => props.portrait ? '100%' : '60%'};
    position: relative;
`;
const FormSup = styled.div`
    margin-bottom: 30px;
    display: ${ props => props.portrait ? 'block' : 'flex'};
    justify-content: space-between;
`;
const FormInf = styled.div`
    margin-bottom: 30px;
`;
const Label = styled.label`
    width: 100%;
`;
const Input = styled.input`
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    padding: 8px;
    font-size: 1.1em;
    font-family: 'Rajdhani';
    width: 90%;
    margin-bottom: ${ props => props.portrait ? '20px' : 0};
`;
const Textarea = styled(Input)`
    height: 200px;
    width: 95%;
`;
const Button = styled.button`
    appearance: none;
    background-color: #903;
    border: solid 2px #903;
    color: #fff;
    padding: 10px 30px;
    border-radius: 100px;
    text-decoration: none;
    font-weight: bold;
    font-size: 1em;
    cursor: pointer;
    transition: background-color .5s, color .5s;
    width: ${ props => props.portrait ? '100%' : '50%'};

    &:hover {
        background-color: transparent;
        color: #903;
    }
`;
const Alerts = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(255,255,255,.9);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #ccc;
`;
const AlertsTextBox = styled.div`
    width: 80%;
`;

export default function ContactForm ({isportrait}) {
    const recaptchaRef = React.createRef();
    
    const [alertsMessajes, setAlertMessajes] = useState({
        show: false,
        message: '',
        showBtn: false,
        btn: ''
    });
    const [toSend, setToSend] = useState({
        from_name: '',
        to_name: '',
        message: '',
        reply_to: '',
        'g-recaptcha-response': ''
    });
    
    const onSubmit = (e) => {
        e.preventDefault();

        const recaptchaValue = recaptchaRef.current.getValue();
        
        if (!recaptchaValue) {
            setAlertMessajes({
                show: true,
                message:'Por favor marque la casilla de Google reCAPTCHA `No soy un robot` para poder enviar su consulta.',
                showBtn: true,
                btn: 'Continuar'
            })
            return;
        }

        setAlertMessajes({
            show: true,
            message:'Enviando su mensaje...',
            showBtn: false,
            btn: ''
        })

        send(
            'service_eusebiocontact',
            'template_z1h1djr',
            toSend,
            'user_VmKaB1JoyTE7FV4J9oh6W'
          )
            .then((response) => {
                setAlertMessajes({
                    show: true,
                    message:'Hemos recibido su consulta. Responderemos a la brevedad. Muchas gracias!',
                    showBtn: true,
                    btn: 'Cerrar'
                })
            })
            .catch((err) => {
                setAlertMessajes({
                    show: true,
                    message: 'Su consulta no pudo procesarse. Por favor intente mas tarde o pongase en contacto via telefonica o Whatsapp.',
                    showBtn: true,
                    btn: 'Cerrar'
                })
            });
      };
    
      const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
      };

      const onChange = (value) => {
        setToSend({ ...toSend, 'g-recaptcha-response': value });
      }

      const cleanForm = () => {
        setToSend({
            from_name: '',
            to_name: '',
            message: '',
            reply_to: '',
            'g-recaptcha-response': ''
        });
        recaptchaRef.current.reset();
      }

      const closeBox = () => {
        alertsMessajes.btn === 'Cerrar' && cleanForm();
        setAlertMessajes({
            show: false,
            message: '',
            showBtn: false,
            btn: ''
        })
      }

      const AlertMessages = ({props}) => {
          return <>
            <Alerts>
                <AlertsTextBox>
                    <div>{props.message}</div>
                    <p></p>
                    {
                        props.showBtn && <Button className="closeBox" onClick={closeBox} portrait={isportrait}>{props.btn}</Button>
                    }
                </AlertsTextBox>
            </Alerts>
          </>
      }

    return (
        <>
        <Form onSubmit={onSubmit} portrait={isportrait}>
            <FormSup portrait={isportrait}>
                <Label>
                    <b>Su Nombre:</b>
                    <br></br>  
                    <Input
                        required
                        type='text'
                        name='from_name'
                        placeholder=''
                        value={toSend.from_name}
                        onChange={handleChange}
                        portrait={isportrait}
                    />  
                </Label>
                <Label>
                    <b>Su Email:</b>
                    <br></br> 
                    <Input
                        required
                        type='text'
                        name='reply_to'
                        placeholder=''
                        value={toSend.reply_to}
                        onChange={handleChange}
                        portrait={isportrait}
                    />
                </Label>
            </FormSup>
            <FormInf>
                <Label>
                    <b>Mensaje:</b>
                    <br></br>
                    <Textarea
                        required
                        type='text'
                        name='message'
                        placeholder=''
                        value={toSend.message}
                        onChange={handleChange}
                    />
                </Label>
            </FormInf>
            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6Ldk80gcAAAAADRqzYY0LhjIACf6gksR0_ea0WRp"
                onChange={onChange}
            />
            <p></p>
            <Button type='submit' portrait={isportrait}>Enviar</Button>
            {
                alertsMessajes.show && <AlertMessages props={alertsMessajes}/>
            }
        </Form>
        </>
    )
}