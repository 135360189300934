import ProductList from "./ProductList";
import Producto3 from "./Producto3";

export default function ProductsAll ({product}) {
    return <>
            {
                product
                     ? ProductList.find( prod => prod.to === product).component
                     : <Producto3/>
            }
        </>
}