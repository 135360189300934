import { withOrientationChange } from 'react-device-detect';
import styled from 'styled-components';

const Gallery = styled.div`
    background-color: #fff;
`;
const ImagesContainer = styled.div`
    display: ${ props => props.portrait ? 'block' : 'flex'};
    flex-wrap: wrap;
`;
const Image = styled.div`
    min-height: 400px;
    background: #ccc;
    flex: calc(100% / 3);
    text-align: center;
    line-height: 400px;
    border: 5px solid #903;
    margin: 5px;
    &.cien {
        background-size: 100% 100%;
        ${ props => props.portrait ? ('min-height: 200px; line-height: 0;') : '' }
    }
    &.vertical {
        background-size:contain;
        background-repeat: no-repeat;
        background-position: center;
    }
`;
const Section = styled.section`
    margin: ${ props => props.portrait ? '0 10%' : '75px 10%'};
    padding: 40px 0;
`;

let Ferias = props => {
    const { isPortrait } = props

    const imagenes = [
        { name: 'Fematec 2004', url:'./fematec-2004.png', size: 'cien' },
        { name: 'Batimat 2004', url:'./batimat-2004.png', size: 'cien' },
        { name: 'Batimat 2005', url:'./batimat-2005.png', size: 'cien' },
        { name: 'Batimat 2006', url:'./batimat-2006.png', size: 'cien' },
        { name: 'Expo Cihac Mexico 2006', url:'./expo-cihac-mexico-2006.png', size: 'vertical' },
        { name: 'Batimat 2007', url:'./batimat-2007.png', size: 'cien' }
    ]

    const Imagenes = ({imagenes}) => {
        return imagenes.map( (imagen, i) => {
            return (
                <Image 
                className={imagen.size}
                portrait={isPortrait}
                key={i*112}
                style={{'backgroundImage':`url('${process.env.PUBLIC_URL}/img/ferias/${imagen.url}')`}}
                >
                </Image>
            )
        });
    }

    return <>
        <div>
            <Section portrait={isPortrait}>
            <h2>Ferias y Exposiciones</h2>
            <p>Participamos en ferías y exposiciones desde el año 2003, nacionales e internacionales y de rondas internacionales de compradores de la construcción y la vivienda organizadas por Fundación Exportar</p>
            </Section>
            <Gallery>
                <Section portrait={isPortrait}>
                    <ImagesContainer portrait={isPortrait}>
                        <Imagenes imagenes={imagenes}/>
                    </ImagesContainer>
                </Section>
            </Gallery>
        </div>
    </>
}

Ferias = withOrientationChange(Ferias)

export default Ferias