import ContactForm from '../../components/ContactForm';
import { withOrientationChange } from 'react-device-detect';
import styled from 'styled-components';

const MapContainer = styled.div`
    background-color: #fff;
    position: relative;

    &::before {
        content: 'Cargando Mapa..';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
`;
const Section = styled.section`
    margin: ${ props => props.portrait ? '0 10%' : '75px 10%'};
    padding: 40px 0;
`;
const FormContainer = styled.div`
    margin-top: ${ props => props.portrait ? '0px' : '-40px'};
`;
const Map = styled.div`
    position: relative;
    background-color: rgba(19, 18, 18, 0.4);
`;

let Contacto = props => {
    const { isPortrait } = props
    
    return <>
        <MapContainer portrait={isPortrait}>
            <Section portrait={isPortrait}>
                <h2>Donde estamos?</h2>
                <p>Sarratea 2168, San Justo, Buenos Aires</p>
                <Map>
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3281.162623670745!2d-58.58010278478672!3d-34.67584488044086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc635f283873d%3A0x5e729e56a4a3bc7c!2sEusebio%20Mart%C3%ADnez!5e0!3m2!1ses!2sar!4v1630726928418!5m2!1ses!2sar" 
                        width="600" 
                        height="450" 
                        style={{border:0, width:'100%'}} 
                        loading="lazy"
                        title="Eusebio Martinez S.R.L.">
                    </iframe>
                </Map>
            </Section>
        </MapContainer>
        <FormContainer portrait={isPortrait}>
            <Section portrait={isPortrait}>
                <h2>Env&iacute;enos un mensaje</h2>
                <p>Escriba su consulta y nos pondremos en contacto a la brevedad.</p>
                <ContactForm isportrait={isPortrait}/>
            </Section>
        </FormContainer>
    </>
}

Contacto = withOrientationChange(Contacto)

export default Contacto