import { BrowserView, MobileView } from 'react-device-detect';
import ContactWidget from '../ContactWidget'
import SocialWidget from '../SocialWidget'
import styled from 'styled-components';

const TopSocialContainer = styled.div`
    background-color: #fff;
    position: relative;
    z-index: 1;
    border-top: solid 1px;
    border-bottom: solid 1px;
`;
const Container = styled.div`
    max-width: 1200px;
    padding-left: 30px;
    padding-right: 30px;
    margin: 0 auto;
`;
const TwoCols = styled.div`
    display: inline-block;
    width: 50%;
`;

export default function TopSocialBar () {
    return <>
        <TopSocialContainer>
            <Container>
                <BrowserView renderWithFragment={true}>
                    <TwoCols>
                        <SocialWidget/>
                    </TwoCols>
                    <TwoCols>
                        <ContactWidget/>
                    </TwoCols>
                </BrowserView>
                <MobileView renderWithFragment={true}>
                    <ContactWidget/>
                </MobileView>
            </Container>
        </TopSocialContainer>
    </>;
};